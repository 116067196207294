import * as yup from 'yup'
import { getUserType } from './userRights'

export const getUserModel = (user: any, isCurrentUser: boolean) => {
  const visibility = user.visibility || {
    products: [],
    groups: [],
    all_products: false,
  }

  if (isCurrentUser) {
    return {
      login: user.login,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    }
  }
  return {
    login: user.login,
    newPassword: '',
    confirmPassword: '',
    userType: getUserType(user.permissions),
    visibility,
  }
}

export const getValidationSchema = (isCurrentUser: boolean) => {
  if (!isCurrentUser) {
    return {
      login: yup.string().required(),
      newPassword: yup
        .string()
        .min(5)
        .notRequired()
        .nullable()
        .transform((_: any, val: any) => String(val) || null),
      confirmPassword: yup
        .string()
        .min(5)
        .notRequired()
        .notRequired()
        .nullable()
        .transform((_: any, val: any) => String(val) || null),
    }
  }
  if (isCurrentUser) {
    return {
      login: yup.string().required(),
      oldPassword: yup.string().min(5).required(),
      newPassword: yup.string().min(5).required(),
      confirmPassword: yup.string().min(5).required(),
    }
  }
}
