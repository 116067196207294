import React, { FC, useState } from 'react'
import { Card, Nav, Tab } from 'react-bootstrap'
import { useFormValidation } from '../../hooks/useFormValidation'
import { UserDataProps } from '../admin/types'
import { getUserModel, getValidationSchema } from '../../utils/controller'
import { AllProductsAccessTypes, User, UserTypes } from '../../entity/User'
import { getUserType } from '../../utils/userRights'
import { Footer } from './components/Footer'
import { Information } from './components/Information'
import { UserPassword } from './components/UserPassword'
import { ProductsVisibility } from '../ProductsVisibility/ProductsVisibility'
import Loader from '../Loader/Loader'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { FormattedMessage } from 'react-intl'

const UserData: FC<UserDataProps> = ({ user, index, currentUserLogin }) => {
  const currentUser = new User()
  const isCurrentUser: boolean = user.login === currentUserLogin
  const userLocal = getUserModel(user, isCurrentUser)
  const schema = getValidationSchema(isCurrentUser)
  const [cashedInputState] = useState(userLocal)
  const [inputState, setInputState, touched, setTouched, validationResult] =
    useFormValidation(userLocal, schema, 'newPassword', 'confirmPassword')
  const administratedUserType = getUserType(user.permissions)
  const currentUserType = getUserType(currentUser.permissions)
  const isReadOnly =
    administratedUserType === UserTypes.OWNER &&
    currentUserType !== UserTypes.OWNER
  const isAllProductsAccesseble = AllProductsAccessTypes?.[inputState.userType]

  if (isCurrentUser) return <></>

  return (
    <Tab.Pane eventKey={user.login} key={user.login}>
      <Card style={{ minWidth: '400px', maxWidth: '700px' }}>
        <Tab.Container id="left-tabs" defaultActiveKey={1}>
          <Card.Header className="nav-dark d-flex">
            <Nav variant="tabs" className="pointer" defaultActiveKey={1}>
              <Nav.Item key={1}>
                <Nav.Link eventKey={1}>
                  <div className="text-capital">
                    <FormattedMessage id="user.userData.generalInformation" />
                  </div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Nav variant="tabs" className="ml-2 pointer" defaultActiveKey={2}>
              <Nav.Item key={2}>
                <Nav.Link eventKey={2}>
                  <div className="text-capital">
                    <FormattedMessage id="user.userData.productsVisibility" />
                  </div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Card className="border-none">
            <Card.Body className="vh-user-card pt-0 p-0 pb-1 ">
              <OverlayScrollbarsComponent className="h-100 ps-4 pe-4">
                <React.Suspense
                  fallback={
                    <div className="vh-full d-flex justify-content-center">
                      <Loader />
                    </div>
                  }
                >
                  <Tab.Content>
                    <Tab.Pane key={1} eventKey={1}>
                      <div>
                        <Information
                          inputState={inputState}
                          setInputState={setInputState}
                          permissions={currentUser.permissions}
                          isReadOnly={isReadOnly}
                          administratedUserType={administratedUserType}
                          user={user}
                          className="mt-4"
                        />
                        <UserPassword
                          isReadOnly={isReadOnly}
                          inputState={inputState}
                          setInputState={setInputState}
                          touched={touched}
                          setTouched={setTouched}
                          validationResult={validationResult}
                          className="mt-4 pt-2"
                        />
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                  <Tab.Content>
                    <Tab.Pane key={2} eventKey={2}>
                      <ProductsVisibility
                        isReadOnly={isReadOnly}
                        isAllProductsAccesseble={isAllProductsAccesseble}
                        inputState={inputState}
                        setInputState={setInputState}
                        cashedInputState={cashedInputState}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </React.Suspense>
              </OverlayScrollbarsComponent>
            </Card.Body>
          </Card>
        </Tab.Container>
        <Footer
          isCurrentUser={isCurrentUser}
          inputState={inputState}
          setInputState={setInputState}
          setTouched={setTouched}
          touched={touched}
          index={index}
          isReadOnly={isReadOnly}
          userLocal={userLocal}
          validationResult={validationResult}
          user={user}
        />
      </Card>
    </Tab.Pane>
  )
}

export default UserData
