import React, { FC, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { hideModal } from '../../store/actions/modalActions'
import { deleteProductsGroup } from '../../store/actions/products/groupsActions'
import { deleteProducts } from '../../store/actions/products/productsActions'
import { RadioButton } from '../inputs/RadioButton'

const DeleteProductsGroupModal: FC = () => {
  const dispatch = useAppDispatch()
  const { data } = useTypedSelector((state) => state.modal)
  const [state, setState] = useState('group')

  const handleClose = (): void => {
    dispatch(hideModal())
  }

  const handleOnApply = (): void => {
    if (state === 'groupAndProducts') {
      dispatch(deleteProducts(data.products))
      dispatch(deleteProductsGroup(data.groupId))
      dispatch(hideModal())
    } else {
      dispatch(deleteProductsGroup(data.groupId))
      dispatch(hideModal())
    }
  }

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header className="ps-4 pe-4" closeButton>
        <Modal.Title>
          <FormattedMessage id={'please-confirm'} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="ps-4 pe-4">
        <RadioButton
          state={state}
          name="group"
          label="summary.deleteGroup"
          setState={setState}
        />
        <RadioButton
          state={state}
          name="groupAndProducts"
          label="summary.deleteGroupAndProducts"
          setState={setState}
        />
      </Modal.Body>
      <Modal.Footer className="ps-4 pe-4">
        <div className="d-flex w-100 justify-content-between pb-1">
          <button
            className="app-btn-cancel btn-primory app-btn-main me-2"
            type="button"
            onClick={handleClose}
          >
            <FormattedMessage id="buttons.cancel" />
          </button>
          <button
            type="button"
            className="app-btn-main app-btn-apply"
            onClick={handleOnApply}
          >
            <FormattedMessage id="buttons.apply" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteProductsGroupModal
