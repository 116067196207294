import { Periods } from '../types'

export const getLowestRange = () =>
  new Date().setDate(new Date().getDate() - 14)

export const periodOptions = [
  { label: 'Custom', value: Periods.CUSTOM },
  { label: 'Today', value: Periods.TODAY },
  { label: 'Last 3 days', value: Periods.THREE_DAYS },
  { label: 'Last week', value: Periods.WEEK },
  { label: 'Last 2 weeks', value: Periods.TWO_WEEKS },
]

export const getPeriod = (
  state: any,
  setLogsState: any,
  from: Date,
  to: Date
) => {
  const startDate = new Date(from)
  const endDate = new Date(to)
  const timeDiff = Math.abs(startDate.getTime() - endDate.getTime())
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))

  switch (diffDays) {
    case 1:
      setLogsState({
        ...state,
        from,
        to,
        period: { label: 'Today', value: Periods.TODAY },
      })
      break

    case 4:
      setLogsState({
        ...state,
        from,
        to,
        period: { label: 'Last 3 days', value: Periods.THREE_DAYS },
      })
      break

    case 8:
      setLogsState({
        ...state,
        from,
        to,
        period: { label: 'Last week', value: Periods.WEEK },
      })
      break

    case 15:
      setLogsState({
        ...state,
        from,
        to,
        period: { label: 'Last 2 weeks', value: Periods.TWO_WEEKS },
      })
      break

    default:
      setLogsState({
        ...state,
        from,
        to,
        period: { label: 'Custom', value: Periods.CUSTOM },
      })
  }
}

export const getRange = (state: any, setLogsState: any) => {
  const date = new Date()

  switch (state?.period?.value) {
    case Periods.TODAY:
      setLogsState({
        ...state,
        from: new Date(),
        to: new Date(),
      })
      break

    case Periods.THREE_DAYS:
      setLogsState({
        ...state,
        from: date?.setDate(date?.getDate() - 3),
        to: new Date(),
      })
      break

    case Periods.WEEK:
      setLogsState({
        ...state,
        from: date?.setDate(date?.getDate() - 7),
        to: new Date(),
      })
      break

    case Periods.TWO_WEEKS:
      setLogsState({
        ...state,
        from: date?.setDate(date?.getDate() - 14),
        to: new Date(),
      })
      break

    default:
      setLogsState({ ...state })
      break
  }
}
