import React, { FC, useEffect, useState } from 'react'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { ConfigurationsActionType } from '../../../types/configurations'
import { buildControlsExt, dateTimePicker } from '../../inputs/controls'
import { IDateTimeProps } from './types'
import { setTimeFormat, transformDate } from './utils'

const DateTime: FC<IDateTimeProps> = ({
  data = {},
  dataSchema = {},
  name,
  dataPath,
}) => {
  const dispatch = useAppDispatch()
  const {
    greater_or_equal,
    greater_than,
    less_or_equal,
    less_than,
    default: defaultValue,
    format: timeSchema,
    description,
    is_required: isRequired,
  } = dataSchema
  const [path] = useState([...(dataPath || []), name])
  const [inputState, setInputState, touched, setTouched, validationResult] =
    useFormValidation(data, {})
  const minDate = greater_or_equal || greater_than
  const maxDate = less_or_equal || less_than
  const isDataEmpty: number = Object.keys(data || {}).length
  const isUndefined: boolean = typeof data?.[name] === 'undefined'
  const timeFormat = setTimeFormat(timeSchema)
  const formattedDate = transformDate(timeFormat, inputState?.[name])

  useEffect(() => {
    if (data && dataPath?.length) {
      setInputState(data)
    }
  }, [data]) // eslint-disable-line

  useEffect(() => {
    if (dataPath?.length) {
      if (!data || !isDataEmpty || isUndefined) {
        dispatch({
          type: ConfigurationsActionType.INIT_EMPTY_DATA,
          payload: { schema: dataSchema, name, path },
        })
      }
      if (defaultValue && !data[name]) {
        setInputState({ ...data, [name]: defaultValue })
      }
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (dataPath?.length) {
      if (!data || !isDataEmpty || isUndefined) {
        dispatch({
          type: ConfigurationsActionType.INIT_EMPTY_DATA,
          payload: { schema: dataSchema, name, path },
        })
      }
      if (JSON.stringify(data) !== JSON.stringify(inputState)) {
        dispatch({
          type: ConfigurationsActionType.DATE_TIME_MODIFY,
          payload: {
            componentState: { [name]: formattedDate },
            path,
            data,
            name,
          },
        })
      }
    }
  }, [inputState, touched]) // eslint-disable-line

  const DateTimeInput = buildControlsExt(
    [
      dateTimePicker(name, {
        timeFormat,
        description,
        isColumn: true,
        minDate,
        maxDate,
        isRequired,
      }),
    ],
    inputState,
    setInputState,
    name,
    touched,
    setTouched,
    validationResult
  )

  return <>{DateTimeInput}</>
}

export default DateTime
