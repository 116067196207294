import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useEffect, useState } from 'react'
import { Collapse } from 'react-bootstrap'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { IGroupDropdown } from '../types'
import { DropdownItem } from './DropdownItem'

export const GroupDropdown: FC<IGroupDropdown> = ({
  group,
  inputState,
  setInputState,
}) => {
  const [open, setOpen] = useState(true)
  const [state, setState] = useState(false)
  const handleOnClick = () => setOpen(!open)
  const isAllProductsChecked = inputState.visibility?.all_products
  const { group_id: groupId } = group
  const isChecked = inputState.visibility?.groups?.indexOf(groupId) > -1

  const onCheckHandle = (event: any) => {
    setState(event.target.checked)
    if (event.target.checked) {
      setInputState({
        ...inputState,
        visibility: {
          ...inputState.visibility,
          groups: [...inputState.visibility.groups, groupId],
          products: [...group.entries],
        },
      })
    } else {
      setInputState({
        ...inputState,
        visibility: {
          ...inputState.visibility,
          groups: inputState.visibility.groups.filter(
            (el: string) => el !== groupId
          ),
        },
      })
    }
  }

  useEffect(() => {
    if (isChecked) {
      setState(true)
    }
  }, [])

  useEffect(() => {
    if (isAllProductsChecked) {
      setState(true)
    }
  }, [isAllProductsChecked])

  return (
    <div className="product-visibility-dropdown ms-3">
      <div className="header">
        <FontAwesomeIcon
          className="icon"
          onClick={handleOnClick}
          icon={open ? faCaretDown : faCaretRight}
        />
        <label className="label">
          <input
            type="checkbox"
            className="checkbox-lg pointer me-1"
            onChange={onCheckHandle}
            checked={state}
            name={group.name}
            disabled={isAllProductsChecked}
          />
          {group.name}
        </label>
      </div>
      <Collapse in={open}>
        {group.entries?.length ? (
          <div className="ms-3">
            {group.entries.map((entry: any) => (
              <DropdownItem
                inputState={inputState}
                setInputState={setInputState}
                isGroupChecked={state}
                entry={entry}
                key={entry}
              />
            ))}
          </div>
        ) : (
          <div className="ms-5 no-data">No entries</div>
        )}
      </Collapse>
    </div>
  )
}
