import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import style from './Styles/index.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { showModal } from '../../store/actions/modalActions'
import { ModalTypes } from '../../types/modals'
import SideBarItem from '../SideBarItem/SideBarItem'
import { routes } from '../../router'
import { User } from '../../entity/User'
import { fetchProducts } from '../../store/actions/products/productsActions'
import { fetchQuickAccessProducts } from '../../store/actions/products/quickAccessActions'
import { fetchProductsGroups } from '../../store/actions/products/groupsActions'

interface ISideBar {
  sidebarHidden: boolean
}

const SideBar: FC<ISideBar> = ({ sidebarHidden }) => {
  const dispatch = useDispatch()
  const user = new User()

  const onLicenseClick = (): void => {
    dispatch(showModal(ModalTypes.LICENCE_MODAL, {}))
  }

  const licence: JSX.Element = (
    <div className="d-flex h-60px w-100 licence" onClick={onLicenseClick}>
      <div
        className={`${
          sidebarHidden
            ? 'd-flex m-auto'
            : 'd-flex align-items-center justify-content-center m-auto w-90'
        }`}
      >
        <FontAwesomeIcon
          icon={faInfo}
          size="1x"
          style={{ marginTop: '2px' }}
          className="text-white"
        />
        {sidebarHidden && (
          <span className="text-light ms-3">TFB Web configurator</span>
        )}
      </div>
    </div>
  )

  useEffect(() => {
    dispatch(fetchProducts(true))
    dispatch(fetchQuickAccessProducts())
    dispatch(fetchProductsGroups())
  }, [])

  useEffect(() => {
    const interval = setInterval(() => dispatch(fetchProducts()), 30000)
    return () => clearInterval(interval)
  }, []) // eslint-disable-line

  return (
    <div
      className={`bg-dark ${style.wrapper} ${
        sidebarHidden ? style.show : style.hidden
      }`}
    >
      <div className="w-100">
        <div className={style.barContainer}>
          {routes.map((item: any) => {
            if (user.permissions.includes(item.permission)) {
              return (
                <SideBarItem
                  name={item.name}
                  route={item.path}
                  icon={item.icon}
                  dropdown={item.dropdown}
                  sidebarHidden={sidebarHidden}
                  key={item.name}
                />
              )
            }
            return <div key={item.name}></div>
          })}
        </div>
      </div>
      {licence}
    </div>
  )
}

export default SideBar
