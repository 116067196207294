import axios from 'axios'
import { Dispatch } from 'redux'
import { ProductActionType } from '../../../types/product'
import { throwErrorMessage, throwSuccessMessage } from '../../../utils/Errors'
import { apiVersion } from '../../../versions'
import { getHeaders } from '../http'

const API = `/api/${apiVersion}`
const API_PRODUCTS = `${API}/products`

export const fetchProducts = (isFirst?: boolean): any => {
  return async (dispatch: Dispatch<any>) => {
    try {
      if (isFirst) {
        dispatch({ type: ProductActionType.FETCH_PRODUCTS })
      }
      const { data } = await axios.get(API_PRODUCTS, {
        headers: getHeaders(),
      })
      dispatch({
        type: ProductActionType.FETCH_PRODUCTS_SUCCESS,
        payload: data,
      })
    } catch (e: any) {
      dispatch({
        type: ProductActionType.FETCH_PRODUCTS_ERROR,
        payload: 'Fetching error',
      })
      throwErrorMessage(e.response?.data?.message, e.response?.status)
    }
  }
}

export const deleteProduct = (
  data: { guid: string; name: string },
  cb?: Function
): any => {
  return async (dispatch: Dispatch<any>) => {
    try {
      await axios.delete(`${API_PRODUCTS}/${data.guid}`, {
        headers: getHeaders(),
      })
      throwSuccessMessage(`Success! ${data.name} deleted!`)
      dispatch({
        type: ProductActionType.DELETE_PRODUCT_SUCCESS,
        payload: { guid: data.guid },
      })
      if (cb) {
        cb()
      }
    } catch (e: any) {
      throwErrorMessage(e.response?.data?.message, e.response?.status)
    }
  }
}

export const deleteProducts = (products: string[]): any => {
  const requests = products.map((guid: string) =>
    axios.delete(`${API_PRODUCTS}/${guid}`, {
      headers: getHeaders(),
    })
  )

  if (products.length) {
    return async (dispatch: Dispatch<any>) => {
      try {
        await axios.all(requests)
        throwSuccessMessage(`Success!`)
        dispatch(fetchProducts())
      } catch (e: any) {
        throwErrorMessage(e.response?.data?.message, e.response?.status)
      }
    }
  }
}

export const renameProduct = (guid: string, name: string): any => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch({ type: ProductActionType.RENAME_PRODUCT, payload: { guid } })
      await axios.put(`${API_PRODUCTS}/${guid}/name`, {
        headers: getHeaders(),
        name,
      })
      dispatch(fetchProducts())
      dispatch({
        type: ProductActionType.RENAME_PRODUCT_SUCCESS,
        payload: { guid },
      })
      throwSuccessMessage('Success!')
    } catch (e: any) {
      throwErrorMessage(e.response?.data?.message, e.response?.status)
      dispatch({
        type: ProductActionType.RENAME_PRODUCT_ERROR,
        payload: { guid },
      })
    }
  }
}