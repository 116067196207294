import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useMemo, useState } from 'react'
import { Card, Collapse } from 'react-bootstrap'
import style from '../Styles/index.module.scss'
import { IProduct, IProductGroup } from '../../../types/product'
import ProductCard from '../../ProductCard/ProductCard'
import { NoData } from '../../NoData'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import AppButton from '../../buttons/AppButton/AppButton'
import { ModalTypes } from '../../../types/modals'
import { showModal } from '../../../store/actions/modalActions'
import { User, UserPermissions } from '../../../entity/User'

interface IProductsGroups {
  productsList: IProduct[]
  groupData: IProductGroup
  hideInactive: boolean
  ref: (element?: HTMLElement | null | undefined) => any
  dragHandleProps?: any
}

export const ProductsGroups: FC<IProductsGroups> = ({
  productsList,
  groupData,
  hideInactive,
  ref,
  dragHandleProps,
}) => {
  const dispatch = useAppDispatch()
  const user = new User()
  const [open, setOpen] = useState(true)
  const { group_id: groupId } = groupData
  const openCloseHandle = () => setOpen(!open)
  const products = productsList.filter((product: IProduct) =>
    groupData?.entries.includes(product.guid)
  )

  const deleteGroupHandle = (): void => {
    dispatch(
      showModal(ModalTypes.DELETE_PRODUCTS_GROUP_MODAL, {
        groupId,
        products: products.map((product: IProduct) => product.guid),
      })
    )
  }

  useMemo(() => {
    const raw = localStorage.getItem(groupId) || 'true'
    setOpen(JSON.parse(raw))
  }, []) // eslint-disable-line

  useMemo(() => {
    localStorage.setItem(groupId, JSON.stringify(open))
  }, [open]) // eslint-disable-line

  return (
    <Card className={style.groupCard}>
      <div
        ref={ref}
        {...dragHandleProps}
        className={open ? style.header : style.headerCollapsed}
      >
        <div
          className="w-100 d-flex align-items-center"
          onClick={openCloseHandle}
          aria-controls="collapse-card"
          aria-expanded={open}
        >
          <h4 className={style.title}> {groupData?.name}</h4>
          <div className="ms-4">
            <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
          </div>
        </div>
        {user.permissions.includes(UserPermissions.GROUP_MANAGEMENT) && (
          <AppButton
            className="app-color-group ms-auto"
            onClick={deleteGroupHandle}
            variant={'delete'}
          />
        )}
      </div>
      <Collapse in={open}>
        <div id="collapse-card">
          {!products.length ? (
            <NoData
              style={{ height: '150px' }}
              messageId={
                hideInactive
                  ? 'summary.noActiveProductsInGroup'
                  : 'summary.noProductsInGroup'
              }
            />
          ) : (
            <div className={style.body}>
              {products.map((product: IProduct) => (
                <ProductCard
                  product={product}
                  groupId={groupId}
                  key={product.guid}
                />
              ))}
            </div>
          )}
        </div>
      </Collapse>
    </Card>
  )
}
