import React, { FC, useEffect, useState } from 'react'
import { Col, Nav, Row, Tab } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { showModal } from '../../store/actions/modalActions'
import { fetchUsers } from '../../store/actions/usersActions'
import { ModalTypes } from '../../types/modals'
import { User, UserPermissions } from '../../entity/User'
import AppButton from '../buttons/AppButton/AppButton'
import Loader from '../Loader/Loader'
import UserData from '../UserData/UserData'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { IUser } from '../../types/user'
import { RoutesPaths } from '../../router'
import { FormattedMessage } from 'react-intl'
import { UsersActionTypes } from '../../types/users'
import { maxRequestTime } from '../../store/actions/http'

const Controller: FC = () => {
  const dispatch = useDispatch()
  const user = new User()
  const currentUserLogin = user.login
  const { users, isLoading } = useTypedSelector((state) => state.user)
  const [isRequestTimeExrired, setIsRequestTimeExrired] = useState(false)
  const defaultUserTab =
    users[0]?.login === currentUserLogin ? users[1]?.login : users[0]?.login
  const isEmpty =
    !users.length || (users.length === 1 && users[0].login === currentUserLogin)

  const handleOnAddUser = (): void => {
    dispatch(showModal(ModalTypes.ADD_NEW_USER_MODAL, {}))
  }

  useEffect(() => {
    dispatch(fetchUsers())
  }, []) // eslint-disable-line

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => setIsRequestTimeExrired(true), maxRequestTime)
    }
  }, [isLoading]) // eslint-disable-line

  useEffect(() => {
    if (isLoading && isRequestTimeExrired) {
      dispatch(
        showModal(ModalTypes.ERROR_MODAL, {
          request: fetchUsers,
          onCancel: 'cancel',
          title: <FormattedMessage id="errors.server.request" />,
          message: <FormattedMessage id="errors.server.requesTimeExceeded" />,
        })
      )
      setIsRequestTimeExrired(false)
      dispatch({ type: UsersActionTypes.FETCH_USERS_CANCEL })
    }
  }, [isRequestTimeExrired]) // eslint-disable-line

  if (!user.permissions.includes(UserPermissions.USER_MANAGEMENT)) {
    return <Redirect to={{ pathname: RoutesPaths.NOT_ENOUGH_PRIVILEGE }} />
  }

  if (isLoading) return <Loader />

  return (
    <div className="ps-4 pb-3 pt-3 pe-4 flex-1">
      <div className="p-4">
        <Tab.Container id="left-tabs-example" defaultActiveKey={defaultUserTab}>
          <Row>
            <Col sm={3}>
              <div className="d-flex justify-content-between">
                <h3 className="app-color-text ms-2">
                  <FormattedMessage id="user.controller.users" />
                </h3>
                <AppButton
                  variant="add"
                  onClick={handleOnAddUser}
                  className="bt btn-add p-1 mt-2 mb-2 me-1 ms-auto ps-3 pe-3"
                  tooltip="Add new user"
                />
              </div>
              <OverlayScrollbarsComponent
                className="pe-3"
                style={{ maxHeight: 'calc(100vh - 170px)' }}
              >
                <Nav variant="pills" className="flex-column">
                  {isEmpty && (
                    <Nav.Item>
                      <div
                        style={{ opacity: '0.5' }}
                        className="h4 text-secondary vh-users d-flex align-items-center justify-content-center"
                      >
                        <FormattedMessage id="user.controller.noData" />
                      </div>
                    </Nav.Item>
                  )}
                  {users.map((user: IUser) => {
                    if (currentUserLogin !== user.login) {
                      return (
                        <Nav.Item key={user.login}>
                          <Nav.Link
                            className="app-color-text user-naw-link"
                            eventKey={user.login}
                          >
                            {user.login}
                          </Nav.Link>
                        </Nav.Item>
                      )
                    }
                    return <></>
                  })}
                </Nav>
              </OverlayScrollbarsComponent>
            </Col>
            <Col className="border-left vh-full" sm={9}>
              <Tab.Content>
                {users.map((user: IUser, index: number) => (
                  <UserData
                    user={user}
                    index={index}
                    currentUserLogin={currentUserLogin}
                    key={user.login}
                  />
                ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  )
}

export default Controller
