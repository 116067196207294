import axios from 'axios'
import { Dispatch } from 'redux'
import { ProductActionType } from '../../../types/product'
import { throwErrorMessage, throwSuccessMessage } from '../../../utils/Errors'
import { apiVersion } from '../../../versions'
import { getHeaders } from '../http'

const API = `/api/${apiVersion}/users/current/quick_access`

export const fetchQuickAccessProducts = (): any => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const { data } = await axios.get(`${API}`, {
        headers: getHeaders(),
      })
      dispatch({
        type: ProductActionType.FETCH_QUICK_ACCESS,
        payload: { products: data },
      })
    } catch (e: any) {
      throwErrorMessage(e.response?.data?.message, e.response?.status)
    }
  }
}

export const quickAccessAddProduct = (guid: string): any => {
  return async (dispatch: Dispatch<any>) => {
    try {
      await axios.post(`${API}`, { product: guid }, { headers: getHeaders() })
      dispatch({
        type: ProductActionType.QUICK_ACCESS_ADD_ITEM,
        payload: { guid },
      })
      dispatch(fetchQuickAccessProducts())
      throwSuccessMessage('Product added to quick access!')
    } catch (e: any) {
      throwErrorMessage(e.response?.data?.message, e.response?.status)
    }
  }
}

export const quickAccessRemoveProduct = (guid: string): any => {
  return async (dispatch: Dispatch<any>) => {
    try {
      await axios.delete(`${API}/${guid}`, {
        headers: getHeaders(),
      })
      dispatch({
        type: ProductActionType.QUICK_ACCESS_REMOVE_ITEM,
        payload: { guid },
      })
      dispatch(fetchQuickAccessProducts())
      throwSuccessMessage('Product removed from quick access!')
    } catch (e: any) {
      throwErrorMessage(e.response?.data?.message, e.response?.status)
    }
  }
}
