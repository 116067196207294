import React, { FC } from 'react'
import { Dropdown } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import style from './Styles/index.module.scss'
import { useDispatch } from 'react-redux'
import { ModalTypes } from '../../../types/modals'
import { showModal } from '../../../store/actions/modalActions'
import { useHistory, useLocation } from 'react-router-dom'
import { RoutesPaths } from '../../../router'
import { deleteProduct } from '../../../store/actions/products/productsActions'

interface IEditConfigBtn {
  guid: string
  productName: string
  handleOnRename: any
  displayName?: string
}

const EditConfigBtn: FC<IEditConfigBtn> = ({
  guid,
  productName,
  handleOnRename,
  displayName,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const currentLocation = useLocation().pathname

  const handleOnDelete = (): void => {
    dispatch(
      showModal(ModalTypes.MAIN_MODAL, {
        body: `Delete ${productName}?`,
        onSubmit: (): void => {
          if (currentLocation.includes(guid)) {
            dispatch(
              deleteProduct({ guid, name: displayName || productName }, () =>
                history.push(RoutesPaths.SUMMARY)
              )
            )
          } else {
            dispatch(deleteProduct({ guid, name: productName }))
          }
        },
      })
    )
  }

  return (
    <Dropdown drop="start">
      <Dropdown.Toggle
        className={style.button}
        variant="outline-secondary"
        id="dropdown-basic"
      >
        <FontAwesomeIcon icon={faEllipsisH} />
      </Dropdown.Toggle>
      <Dropdown.Menu className={style.menu}>
        <Dropdown.Item eventKey="1" onClick={handleOnDelete}>
          <FormattedMessage id="buttons.remove" />
        </Dropdown.Item>
        <Dropdown.Item eventKey="2" onClick={handleOnRename}>
          <FormattedMessage id="buttons.rename" />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default EditConfigBtn
