import { getMultiSelectOptionsProductsGroups } from '.'
import { IProductGroup } from '../../../../types/product'

export const getGroupData = (id: string, groups: IProductGroup[]) => {
  const target = groups.find((group: IProductGroup) => group.group_id === id)
  if (target) {
    return {
      groupId: id,
      groupsList: getMultiSelectOptionsProductsGroups(groups),
      group: getMultiSelectOptionsProductsGroups(groups)[0],
      name: target.name,
    }
  }
  return
}

export const getFormattedGroupData = (groupState: any) => {
  const keys = Object.keys(groupState)

  const formattedData = keys.reduce((acc: any, res) => {
    if (Array.isArray(groupState[res])) {
      return { ...acc, [res]: groupState[res].map((el: any) => el.value) }
    }
    if (res === 'entries') {
      if (!groupState.entries) {
        return {
          ...acc,
          entries: [],
        }
      }
      return {
        ...acc,
        entries: groupState.entries.map((entry: any) => entry.value),
      }
    }
    if (res === 'groupId' || res === 'group') {
      return { ...acc }
    }
    return { ...acc, [res]: groupState[res] }
  }, {})

  return formattedData
}
