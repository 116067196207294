import axios from 'axios'
import { Dispatch } from 'redux'
import { ProductActionType } from '../../../types/product'
import { throwErrorMessage, throwSuccessMessage } from '../../../utils/Errors'
import { apiVersion } from '../../../versions'
import { getHeaders } from '../http'
import { hideModal } from '../modalActions'

const API = `/api/${apiVersion}/groups`

export const fetchProductsGroups = (): any => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const { data } = await axios.get(`${API}`, {
        headers: getHeaders(),
      })
      dispatch({
        type: ProductActionType.FETCH_PRODUCTS_GROUPS,
        payload: { productsGroups: data },
      })
    } catch (e: any) {
      throwErrorMessage(e.response?.data?.message, e.response?.status)
    }
  }
}

export const changeProductsGroupsPositions = (positions: string[]): any => {
  return async (dispatch: Dispatch<any>) => {
    try {
      await axios.put(
        `${API}/positions`,
        { positions },
        { headers: getHeaders() }
      )
      dispatch(fetchProductsGroups())
    } catch (e: any) {
      throwErrorMessage(e.response?.data?.message, e.response?.status)
    }
  }
}

export const createProductsGroup = (data: any): any => {
  return async (dispatch: Dispatch<any>) => {
    try {
      await axios.post(
        `${API}`,
        { ...data, position: 0 },
        { headers: getHeaders() }
      )
      dispatch(fetchProductsGroups())
      dispatch(hideModal())
      throwSuccessMessage(`Group ${data.name} created!`)
    } catch (e: any) {
      throwErrorMessage(e.response?.data?.message, e.response?.status)
    }
  }
}

export const deleteProductsGroup = (groupId: string, cb?: any): any => {
  return async (dispatch: Dispatch<any>) => {
    try {
      await axios.delete(`${API}/${groupId}`, {
        headers: getHeaders(),
      })
      dispatch(fetchProductsGroups())
      throwSuccessMessage(`Success!`)
      if (cb) {
        cb()
      }
    } catch (e: any) {
      throwErrorMessage(e.response?.data?.message, e.response?.status)
    }
  }
}

export const editProductsGroup = (groupId: string, data: any, cb: any): any => {
  return async (dispatch: Dispatch<any>) => {
    try {
      await axios.put(`${API}/${groupId}`, data, {
        headers: getHeaders(),
      })
      dispatch(fetchProductsGroups())
      throwSuccessMessage('Success!')
      if (cb) {
        cb()
      }
    } catch (e: any) {
      throwErrorMessage(e.response?.data?.message, e.response?.status)
    }
  }
}

export const renameProductsGroup = (groupId: string, name: string): any => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch({
        type: ProductActionType.RENAME_PRODUCT_GROUP,
        payload: { name },
      })
      await axios.put(`${API}/${groupId}/name`, {
        headers: getHeaders(),
        name,
      })
      dispatch(fetchProductsGroups())
      dispatch({
        type: ProductActionType.RENAME_PRODUCT_GROUP_SUCCESS,
        payload: { groupId, name },
      })
      throwSuccessMessage('Success!')
    } catch (e: any) {
      throwErrorMessage(e.response?.data?.message, e.response?.status)
      dispatch({
        type: ProductActionType.RENAME_PRODUCT_GROUP_ERROR,
        payload: { groupId, name },
      })
    }
  }
}

export const deleteGroupEntry = (groupId?: string, guid?: string): any => {
  return async (dispatch: Dispatch<any>) => {
    try {
      await axios.delete(`${API}/${groupId}/entries/${guid}`, {
        headers: getHeaders(),
      })
      dispatch(fetchProductsGroups())
      throwSuccessMessage(`Success!`)
    } catch (e: any) {
      throwErrorMessage(e.response?.data?.message, e.response?.status)
    }
  }
}

export const changeGroupPositions = (positions: string[]): any => {
  return async (dispatch: Dispatch<any>) => {
    try {
      await axios.put(
        `${API}/positions`,
        { positions },
        { headers: getHeaders() }
      )
      dispatch(fetchProductsGroups())
      throwSuccessMessage(`Success!`)
    } catch (e: any) {
      throwErrorMessage(e.response?.data?.message, e.response?.status)
    }
  }
}
