import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { fetchProduct } from '../../../store/actions/configurationActions'
import { IProduct, ProductActionType } from '../../../types/product'
import { getLabel } from '../../../validators'
import EditConfigBtn from '../../buttons/EditConfigBtn/EditConfigBtn'
import ProductNameInput from '../../inputs/ProductNameInput'
import style from '../Styles/index.module.scss'
import { UnsavedChangesMark } from '../../UnsavedChangesMark'
import { Nav } from 'react-bootstrap'
import { renameProduct } from '../../../store/actions/products/productsActions'
import { User, UserTypes } from '../../../entity/User'
import { getUserType } from '../../../utils/userRights'

interface IProductsDropdownItem {
  product: IProduct
  isPopover?: boolean
}

const ProductsDropdownItem: FC<IProductsDropdownItem> = ({
  product,
  isPopover,
}): JSX.Element => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { permissions } = new User()
  const history = useHistory()
  const { guid, name, status, display_name: displayName } = product
  const userType = getUserType(permissions)
  const isWLManager = userType === UserTypes.WL_MANAGER
  const isActive = location?.pathname?.includes(guid)
  const { productsConfigurations, unsaved } = useTypedSelector(
    (state) => state.configurations
  )
  const [isProductNameEditing, setIsProductNameEditing] =
    useState<boolean>(false)
  const [productName, setProductName] = useState<string>(
    displayName || getLabel(name)
  )
  const [editConfigBtnShow, setEditConfigBtnShow] = useState(false)
  const isProductActive: boolean = status?.toLowerCase() === 'active'

  const handleOnRename = (): void => {
    setIsProductNameEditing(true)
  }

  const handleOnSave = (): void => {
    setIsProductNameEditing(false)
    dispatch(renameProduct(guid, productName))
  }

  const handleOnCancel = (): void => {
    setIsProductNameEditing(false)
    setProductName(displayName || getLabel(name))
  }

  const handleOnClick = (): void => {
    if (isProductActive) {
      dispatch({ type: ProductActionType.SET_PRODUCT_ROUTED, payload: true })
      history.push(`/${guid}`)
    }
    if (!productsConfigurations[guid] && isProductActive) {
      dispatch(fetchProduct(guid))
    }
  }

  const onMouseOver = () => {
    setEditConfigBtnShow(true)
  }

  const onMouseLeave = () => {
    setEditConfigBtnShow(false)
  }

  useEffect(() => {
    setProductName(displayName || getLabel(name))
  }, [displayName, name])

  return (
    <div
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      className={`d-flex align-items-center justify-content-between w-100 ${
        isPopover && 'pb-1'
      }`}
    >
      {isProductNameEditing ? (
        <ProductNameInput
          name={productName}
          setProductName={setProductName}
          handleOnSave={handleOnSave}
          handleOnCancel={handleOnCancel}
          className="mb-3 mt-3"
          buttonsClassName="app-border-color"
          feedbackClassName="feedback-absolute"
          size="sm"
        />
      ) : (
        <>
          <Nav.Link
            onClick={handleOnClick}
            className={`${!isProductActive && 'cursor-default'} dropdownLink ${
              isActive ? 'active' : ''
            }`}
            style={{ width: !isPopover ? '80%' : '85%' }}
          >
            <span
              className={`${!isProductActive && 'inactive'} dropdown-label ${
                isPopover ? 'ms-4' : ''
              }`}
            >
              <span className="p-relative">
                <span
                  className={[
                    style.dropdownProductLabel,
                    !isProductActive && style.productInactive,
                    'text-capitalize word-break',
                  ].join(' ')}
                >
                  {productName}
                </span>
                {unsaved[guid] && (
                  <UnsavedChangesMark
                    className={
                      !isPopover
                        ? style.editIndicator
                        : style.editIndicatorPopover
                    }
                  />
                )}
              </span>
            </span>
          </Nav.Link>
          <div
            className={`${
              !isPopover && 'app-w-50'
            } d-flex align-items-center p-relative pe-1`}
            style={{ width: !isPopover ? '20%' : '15%' }}
          >
            {editConfigBtnShow && !isPopover && !isWLManager && (
              <EditConfigBtn
                guid={guid}
                productName={name}
                handleOnRename={handleOnRename}
                displayName={displayName}
              />
            )}
            <span
              style={{ background: isProductActive ? '#6FD1B9' : '#F48A8A' }}
              className={style.workStatusIndicator}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default ProductsDropdownItem
