import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import React, { FC, useEffect, useState } from 'react'
import { Card, Collapse } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { UnsavedChangesMark } from '../UnsavedChangesMark'
import style from './Styles/index.module.scss'
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { GroupDropdown } from './components/GroupDropdown'
import { OthersPropdown } from './components/OthersPropdown'
import { IProductsVisibility } from './types'
import { IProduct } from '../../types/product'

export const ProductsVisibility: FC<IProductsVisibility> = ({
  isReadOnly,
  inputState,
  setInputState,
  isAllProductsAccesseble,
  cashedInputState,
}) => {
  const [open, setOpen] = useState(true)
  const [disabled, setDisabled] = useState(false)
  const { productsGroups, products } = useTypedSelector(
    (state) => state.products
  )

  useEffect(() => {
    if (isAllProductsAccesseble) {
      setInputState({
        ...inputState,
        visibility: {
          ...inputState.visibility,
          all_products: true,
        },
      })
      setDisabled(true)
    } else {
      setInputState({
        ...inputState,
        visibility: { ...cashedInputState.visibility },
      })
      setDisabled(false)
    }
  }, [isAllProductsAccesseble])

  const allProductsHandle = (event: any) => {
    if (event.target.checked) {
      setInputState({
        ...inputState,
        visibility: {
          ...inputState.visibility,
          all_products: event.target.checked,
          groups: productsGroups.map((group: any) => group.group_id),
          products: products.map((product: IProduct) => product.guid),
        },
      })
    } else {
      setInputState({
        ...inputState,
        visibility: {
          ...inputState.visibility,
          all_products: event.target.checked,
        },
      })
    }
  }

  const mainContent: JSX.Element = (
    <>
      <p className={style.notification}>
        <UnsavedChangesMark className="me-1 app-color-default" />
        <FormattedMessage id="user.userData.notificationText" />
      </p>

      <div className="product-visibility-dropdown">
        <div className="border rounded">
          <OverlayScrollbarsComponent
            className="p-3"
            style={{ height: '260px' }}
          >
            <div className="header">
              <FontAwesomeIcon
                className="icon"
                onClick={() => setOpen(!open)}
                icon={open ? faCaretDown : faCaretRight}
              />
              <label className="label">
                <input
                  type="checkbox"
                  className="checkbox-lg pointer me-1"
                  onChange={allProductsHandle}
                  checked={inputState.visibility?.all_products}
                  name="all_products"
                  disabled={disabled}
                />
                All products
              </label>
            </div>
            <Collapse in={open}>
              <div>
                <div>
                  {productsGroups.map((group: any) => (
                    <GroupDropdown
                      group={group}
                      inputState={inputState}
                      setInputState={setInputState}
                      key={group.id}
                    />
                  ))}
                </div>
                <div>
                  <OthersPropdown
                    inputState={inputState}
                    setInputState={setInputState}
                  />
                </div>
              </div>
            </Collapse>
          </OverlayScrollbarsComponent>
        </div>
      </div>
    </>
  )

  return (
    <div className="pt-3">
      <Card.Text>
        <p className="label-main text-capital pt-1">
          <FormattedMessage id="user.userData.chooseVisibleProducts" />
        </p>
      </Card.Text>
      {mainContent}
    </div>
  )
}
