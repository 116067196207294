import { faBookmark, faBoxesStacked } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { Nav } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'
import { User, UserPermissions } from '../../../entity/User'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { RoutesPaths } from '../../../router'
import { showModal } from '../../../store/actions/modalActions'
import { ModalTypes } from '../../../types/modals'
import CheckboxInput from '../../inputs/CheckboxInput'
import style from '../Styles/index.module.scss'

interface ISummaryHeader {
  inactiveProductsCheck: boolean
  hideInactiveState: { showActive: boolean }
  setHideInactiveState: Dispatch<SetStateAction<{ showActive: boolean }>>
}

export const SummaryHeader: FC<ISummaryHeader> = ({
  inactiveProductsCheck,
  hideInactiveState,
  setHideInactiveState,
}) => {
  const user = new User()
  const dispatch = useAppDispatch()
  const { productsGroups } = useTypedSelector((state) => state.products)

  const createGroupHandle = (): void => {
    dispatch(showModal(ModalTypes.CREATE_PRODUCTS_GROUPS_MODAL))
  }

  const editGroupHandle = (): void => {
    dispatch(showModal(ModalTypes.EDIT_GROUPS_MODAL))
  }

  return (
    <div className={style.header}>
      <div className="d-flex align-items-center">
        <Nav.Link className="p-0 m-0">
          <NavLink className="summary-nav-link" to={RoutesPaths.PRODUCTS}>
            <FontAwesomeIcon className="me-2" icon={faBoxesStacked} size="xs" />
            <FormattedMessage id="summary.products" />
          </NavLink>
        </Nav.Link>
        <Nav.Link className="p-0 m-0">
          <NavLink className={`summary-nav-link`} to={RoutesPaths.QUICK_ACCESS}>
            <FontAwesomeIcon className="me-2" icon={faBookmark} size="xs" />
            <FormattedMessage id="summary.quickAccess" />
          </NavLink>
        </Nav.Link>
      </div>
      <div className="d-flex align-items-center">
        {inactiveProductsCheck && (
          <CheckboxInput
            className="me-5"
            state={hideInactiveState}
            name="showActive"
            label="Hide inactive"
            setState={setHideInactiveState}
            checkboxType="small"
          />
        )}
        {user.permissions.includes(UserPermissions.GROUP_MANAGEMENT) && (
          <>
            {productsGroups.length ? (
              <button
                type="button"
                onClick={editGroupHandle}
                className="app-btn-apply app-btn-main ms-5 me-2"
              >
                <FormattedMessage id="summary.editGroup" />
              </button>
            ) : (
              <></>
            )}
            <button
              type="button"
              onClick={createGroupHandle}
              className="app-btn-reset app-btn-main "
            >
              <FormattedMessage id="summary.createGroup" />
            </button>
          </>
        )}
      </div>
    </div>
  )
}
