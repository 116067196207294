import { throwErrorMessage } from '../../utils/Errors'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import axios from 'axios'
import { getIsPrevUser } from '../../utils/checkPrevUser'
import { RoutesPaths } from '../../router'
import {
  getJwt,
  getTokenRefresh,
  saveJwt,
  saveUser,
  tokenRefresh,
} from '../../utils/authentication'

export const fetchSignIn = async (
  login: string,
  password: string,
  onSuccess: any
): Promise<void> => {
  try {
    const { data } = await axios.post(`/api/oauth/authorize`, {
      login,
      password,
    })
    saveJwt(data.access_token)
    tokenRefresh(data.refresh_token)
    saveUser(data)
    onSuccess()
    getIsPrevUser(login)
  } catch (e: any) {
    if (e.response.status === 404) {
      throwErrorMessage('User not found.')
      return
    }
    if (e.response.status === 403) {
      throwErrorMessage('Password or login incorrect.')
      return
    }
    throwErrorMessage(e.response?.data?.message, e.response?.status)
  }
}

const refreshAuth = async (failedRequest: any): Promise<void> => {
  const credentials = await axios.post(RoutesPaths.REFRESH_TOKEN, {
    refresh_token: getTokenRefresh(),
  })
  saveJwt(credentials.data.access_token)
  tokenRefresh(credentials.data.refresh_token)
  saveUser(credentials.data)
  failedRequest.response.config.headers[
    'Authorization'
  ] = `Bearer ${credentials.data.access_token}`
}

axios.interceptors.request.use((response: any) => {
  response.headers['Authorization'] = `Bearer ${getJwt()}`
  return response
})

createAuthRefreshInterceptor(axios, refreshAuth)
