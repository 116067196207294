import { faFolder } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useEffect, useState } from 'react'
import { Collapse } from 'react-bootstrap'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { IProduct } from '../../../types/product'
import { IDropdownItem } from '../types'

export const DropdownItem: FC<IDropdownItem> = ({
  entry,
  inputState,
  setInputState,
  isGroupChecked,
}) => {
  const { products } = useTypedSelector((state) => state.products)
  const [open, setOpen] = useState(false)
  const [state, setState] = useState(false)
  const [hovered, setHovered] = useState(false)
  const handleOnClick = () => setOpen(!open)
  const product = products.find((product: IProduct) => product.guid === entry)
  const isAllProductsChecked = inputState.visibility?.all_products
  const isChecked = inputState.visibility?.products?.indexOf(product?.guid) > -1

  const onCheckHandle = (event: any): void => {
    setState(event.target.checked)
    if (event.target.checked) {
      setInputState({
        ...inputState,
        visibility: {
          ...inputState.visibility,
          products: [...inputState.visibility.products, product.guid],
        },
      })
    } else {
      setInputState({
        ...inputState,
        visibility: {
          ...inputState.visibility,
          products: inputState.visibility.products.filter(
            (el: string) => el !== product.guid
          ),
        },
      })
    }
  }

  const handleOnMouseEnter = (): void => {
    setHovered(true)
  }

  const handleOnMouseLeave = (): void => {
    setHovered(false)
  }

  useEffect(() => {
    if (isChecked) {
      setState(true)
    }
  }, [])

  useEffect(() => {
    if (isAllProductsChecked || isGroupChecked) {
      setState(true)
    }
  }, [isAllProductsChecked, isGroupChecked])

  return (
    <div className={`product-dropdown-item ${open && 'pb-1'}`}>
      <div
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        className="d-flex justify-content-between w-100"
      >
        <label>
          <input
            type="checkbox"
            className="checkbox-lg pointer me-1"
            onChange={onCheckHandle}
            checked={state}
            name={product?.guid}
            disabled={isAllProductsChecked || isGroupChecked}
          />
          {product?.display_name || product?.name}
        </label>
        {hovered ? (
          <div>
            <FontAwesomeIcon
              onClick={handleOnClick}
              className="icon"
              icon={faFolder}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <Collapse in={open}>
        <div className="rounded border p-2 ">{product?.path}</div>
      </Collapse>
    </div>
  )
}
