import React, { FC } from 'react'
import { Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { hideModal } from '../../../store/actions/modalActions'
import { Footer } from './components/Footer'
import { Group } from './components/Group'
import * as yup from 'yup'
import { createProductsGroup } from '../../../store/actions/products/groupsActions'

const schema = {
  name: yup.string().required().min(1),
}

export const CreateProductsGroupsModal: FC = () => {
  const dispatch = useAppDispatch()
  const [groupState, setGroupState, touched, setTouched, validationResult] =
    useFormValidation(
      {
        name: '',
        entries: [],
      },
      schema
    )

  const handleClose = (): void => {
    dispatch(hideModal())
  }

  const handleApply = (): void => {
    const data = {
      name: groupState.name,
      type: 'products',
      entries: groupState.entries.map((el: any) => el.value),
    }
    dispatch(createProductsGroup(data))
  }

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header className="ps-4 pe-4" closeButton>
        <Modal.Title>
          <FormattedMessage id="summary.createNewGroup" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="ps-4 pe-4">
        <div className="m-0">
          <Group
            groupState={groupState}
            setGroupState={setGroupState}
            touched={touched}
            setTouched={setTouched}
            validationResult={validationResult}
          />
        </div>
      </Modal.Body>
      <Footer
        validationResult={validationResult}
        handleClose={handleClose}
        handleApply={handleApply}
      />
    </Modal>
  )
}
