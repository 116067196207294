import Cookies from 'universal-cookie'
import { User } from '../entity/User'
import { hashCode } from './math'

const cookies = new Cookies()

export const saveJwt = (jwt: string): void => {
  cookies.set('jwt', jwt, { maxAge: 60 * 30 })
}

export const getJwt = (): string | null => cookies.get('jwt')
export const removeJwt = (): void => cookies.remove('jwt')
export const removeUser = (): void => localStorage.removeItem('user')

export const tokenRefresh = (refresh: string) => cookies.set('refresh', refresh)
export const getTokenRefresh = (): string | null => cookies.get('refresh')
export const removeTokenRefresh = (): void => cookies.remove('refresh')

export const signOut = (): void => {
  const user = new User()
  const userhashedLogin = hashCode(user.login)
  localStorage.setItem('prev', JSON.stringify(userhashedLogin))
  removeJwt()
  removeUser()
  removeTokenRefresh()
}

export const getUsername = (): string => {
  const user = localStorage.getItem('user')
  if (user === null) {
    return 'Unknown'
  }
  return JSON.parse(user).login || ''
}

export const getPermissions = (): string[] => {
  if (!localStorage.getItem('user')) return []
  const user = JSON.parse(localStorage.getItem('user') || '')

  const permissions = user?.permissions

  if (!user || !Array.isArray(permissions)) {
    return []
  }

  return permissions
}

export const saveUser = (data: any): void => {
  const user = JSON.parse(atob(data.access_token.split('.')[1]))

  localStorage.setItem(
    'user',
    JSON.stringify({
      login: user.name,
      permissions: user.roles,
    })
  )
}
