import React, { FC, useState } from 'react'
import { Options } from 'overlayscrollbars'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import style from '../Styles/index.module.scss'
import { Card, Collapse } from 'react-bootstrap'
import { IProduct } from '../../../types/product'
import { FormattedMessage } from 'react-intl'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { UnsavedChangesMark } from '../../UnsavedChangesMark'

interface IProductCardBody {
  product: IProduct
}

const ProductCardBody: FC<IProductCardBody> = ({ product }) => {
  const { name, status, version, guid, path } = product
  const { unsaved } = useTypedSelector((state) => state.configurations)
  const [open, setOpen] = useState(false)
  const [isAppNameHovered, setIsAppNameHovered] = useState(false)
  const onAppNameHover = () => setIsAppNameHovered(true)
  const onAppNameLeave = () => setIsAppNameHovered(false)
  const isUnsaved: boolean = unsaved[guid]

  const showPathHandle = (): void => {
    setOpen((prev) => !prev)
  }

  return (
    <Card.Body>
      <div className="mt-2">
        <p className={style.descriptionAppName}>
          <span className="app-color-text fw-500 me-1">
            <FormattedMessage id="productCard.appName" />
          </span>
          {!isAppNameHovered ? (
            <span
              className={style.descriptionNotHovered}
              onMouseEnter={onAppNameHover}
            >
              {name}
            </span>
          ) : (
            <OverlayScrollbarsComponent
              className={style.descriptionHovered}
              options={
                {
                  overflowBehavior: { x: 'scroll', y: 'visible-hidden' },
                } as Options
              }
              onMouseLeave={onAppNameLeave}
            >
              {name}
            </OverlayScrollbarsComponent>
          )}
        </p>
        <p className={style.description}>
          <span className="app-color-text fw-500 me-1">
            <FormattedMessage id="productCard.status" />
          </span>
          {status.toLowerCase()}{' '}
          {status.toLowerCase() === 'active' ? (
            <span className={style.active} />
          ) : (
            <span className={style.inactive} />
          )}
        </p>
        <p className={style.description}>
          <span className="app-color-text fw-500 me-1">
            <FormattedMessage id="productCard.version" />
          </span>
          {version}
        </p>

        <p>
          {isUnsaved ? (
            <div className="app-bg-gray ps-1 pe-1 fit-content">
              <UnsavedChangesMark className="me-1 app-color-default" />
              <FormattedMessage id="productCard.unsavedChanges" />
            </div>
          ) : (
            <FormattedMessage id="productCard.upToDate" />
          )}
        </p>

        <div>
          <div className={style.control}>
            <button
              type="button"
              onClick={showPathHandle}
              className="app-btn-apply app-btn-main app-btn-reset app-btn-large"
              aria-controls={guid}
              aria-expanded={open}
            >
              {!open ? (
                <FormattedMessage id="productCard.showFullPath" />
              ) : (
                <FormattedMessage id="productCard.hideFullPath" />
              )}
            </button>
          </div>
          <Collapse in={open}>
            <div id={guid} className="border p-1 rounded mt-2">
              {path || '/'}
            </div>
          </Collapse>
        </div>
      </div>
    </Card.Body>
  )
}

export default ProductCardBody
