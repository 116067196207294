import React, { FC } from 'react'
import { Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { hideModal } from '../../../store/actions/modalActions'
import { Body } from './components/Body'
import { Footer } from './components/Footer'
import { initialState } from './types'

export const LogsModal: FC = () => {
  const { data } = useTypedSelector((state) => state.modal)
  const dispatch = useAppDispatch()
  const [logsState, setLogsState] = useFormValidation(initialState, {})

  const handleClose = (): void => {
    dispatch(hideModal())
  }

  const handleOnSubmit = (): void => {
    data?.onSubmit()
    handleClose()
  }

  return (
    <Modal className="mt-5" show={true} onHide={handleClose}>
      <Modal.Header className="ps-4 pe-4" closeButton>
        <Modal.Title>
          <FormattedMessage id="modals.logs.title" />
        </Modal.Title>
      </Modal.Header>
      <div className="ps-4 pe-4">
        <Body logsState={logsState} setLogsState={setLogsState} />
      </div>
      <div>
        <Footer handleOnSubmit={handleOnSubmit} handleClose={handleClose} />
      </div>
    </Modal>
  )
}
