import React, { FC } from 'react'
import { Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { controllerErrors } from '../../../utils/validationErrors/controllerErrors'
import { passwordInput, buildControlsExt } from '../../inputs/controls'

interface IUserPassword {
  isReadOnly: boolean
  inputState: any
  setInputState: any
  touched: any
  setTouched: any
  validationResult: any
  className?: string
}

export const UserPassword: FC<IUserPassword> = ({
  isReadOnly,
  inputState,
  setInputState,
  touched,
  setTouched,
  validationResult,
  className,
}) => {
  const passwordInputs = buildControlsExt(
    [
      passwordInput({
        name: 'newPassword',
        label: 'New password',
        isColumn: true,
        className: 'mb-2 label-sm',
        errMsg: controllerErrors.adminControllerNewPassword(inputState),
      }),
      passwordInput({
        name: 'confirmPassword',
        label: 'Repeat password',
        isColumn: true,
        className: 'mb-2 label-sm',
        errMsg: controllerErrors.adminControllerRepeatPassword(inputState),
      }),
    ],
    inputState,
    setInputState,
    '',
    touched,
    setTouched,
    validationResult
  )

  return (
    <>
      {!isReadOnly && (
        <Card.Text className={`${className} pb-4`}>
          <>
            <p className="navi-blue mb-4 h5">
              <FormattedMessage id="user.userData.changePassword" />
            </p>
            <div id="example-collapse-text">{passwordInputs}</div>
          </>
        </Card.Text>
      )}
    </>
  )
}
