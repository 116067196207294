import React from 'react'
import DateTime from '../models/DateTime/DateTime'
import DBConnection from '../models/blockModels/DBConnection/DBConnection'
import Double from '../models/Double/Double'
import Duration from '../models/Duration/Duration'
import Enum from '../models/Enum/Enum'
import Flag from '../models/Flag/Flag'
import Integer from '../models/Integer/Integer'
import MailSender from '../models/blockModels/MailSender/MailSender'
import MtConnection from '../models/blockModels/MtConnection/MtConnection'
import Secret from '../models/Secret/Secret'
import StringInput from '../models/StringInput/StringInput'
import { ModelTypes } from '../models/types'
import { InputViewOnly } from '../ViewOnly/InputViewOnly'
import { getIsViewOnly } from '../../utils/viewOnly'

const Block = React.lazy(() => import('../models/Block/Block'))
const BlockArray = React.lazy(() => import('../models/BlockArray/BlockArray'))
const BlockDictionary = React.lazy(
  () => import('../models/BlockDictionary/BlockDictionary')
)
const WrappedBlock = React.lazy(() => import('../models/Block/WrappedBlock'))

interface IElements {
  [key: string]: JSX.Element
}

export function buildElementsExt(
  name: string,
  ProductScheme: any = {},
  ProductConfiguration: any = {},
  wrapper?: string,
  path?: any,
  isParentDisabled?: boolean,
  levelDeep?: number
): JSX.Element {
  const elementType: string = ProductScheme?.type || 'default'
  const isViewOnly = getIsViewOnly(ProductScheme.is_access_restricted)

  const elements: IElements = {
    [ModelTypes.MT_CONNECTION]: (
      <MtConnection
        title={ProductScheme?.name}
        data={ProductConfiguration?.[name]}
        name={name}
        dataSchema={ProductScheme}
        wrapper={wrapper || ''}
        dataPath={path}
        isParentDisabled={isParentDisabled}
        key={name + path}
      />
    ),
    [ModelTypes.DB_CONNECTION]: (
      <DBConnection
        title={ProductScheme?.name}
        data={ProductConfiguration?.[name]}
        dataSchema={ProductScheme}
        name={name}
        wrapper={wrapper || ''}
        dataPath={path}
        isParentDisabled={isParentDisabled}
        key={name + path}
      />
    ),
    [ModelTypes.MAIL_SENDER]: (
      <MailSender
        title={ProductScheme?.name}
        data={ProductConfiguration?.[name]}
        dataSchema={ProductScheme}
        name={name}
        wrapper={wrapper || ''}
        dataPath={path}
        isParentDisabled={isParentDisabled}
        key={name + path}
      />
    ),
    [ModelTypes.BLOCK]: !wrapper ? (
      <Block
        title={ProductScheme?.name}
        data={ProductConfiguration?.[name]}
        dataSchema={ProductScheme}
        name={name}
        wrapper={wrapper || ''}
        dataPath={path}
        isParentDisabled={isParentDisabled}
        key={name + path}
      />
    ) : (
      <WrappedBlock
        title={ProductScheme?.name}
        data={ProductConfiguration?.[name]}
        dataSchema={ProductScheme}
        name={name}
        wrapper={wrapper || 'block'}
        dataPath={path}
        isParentDisabled={isParentDisabled}
        levelDeep={levelDeep}
        key={name + path}
      />
    ),
    [ModelTypes.BLOCK_ARRAY]: (
      <BlockArray
        config={ProductConfiguration?.[name]}
        schema={ProductScheme}
        title={name}
        dataPath={path}
        wrapper={wrapper}
        key={name + path}
        levelDeep={levelDeep}
        isViewOnly={isViewOnly}
      />
    ),
    [ModelTypes.BLOCK_DICTIONARY]: (
      <BlockDictionary
        wrapper={wrapper}
        dataSchema={ProductScheme}
        name={name}
        data={ProductConfiguration?.[name]}
        dataPath={path}
      />
    ),
    [ModelTypes.FLAG]: (
      <Flag
        data={ProductConfiguration}
        dataSchema={ProductScheme}
        name={name}
        dataPath={path}
        isViewOnly={isViewOnly}
        key={name + path}
      />
    ),
    [ModelTypes.BOOL]: (
      <Flag
        data={ProductConfiguration}
        dataSchema={ProductScheme}
        name={name}
        dataPath={path}
        isViewOnly={isViewOnly}
        key={name + path}
      />
    ),
    [ModelTypes.ENUM]: isViewOnly ? (
      <InputViewOnly
        name={name}
        title={ProductScheme.name}
        value={ProductConfiguration?.[name]}
        key={name + path}
      />
    ) : (
      <Enum
        data={ProductConfiguration}
        dataSchema={ProductScheme}
        name={name}
        wrapper={wrapper || ''}
        dataPath={path}
        key={name + path}
      />
    ),
    [ModelTypes.INTEGER]: isViewOnly ? (
      <InputViewOnly
        name={name}
        title={ProductScheme.name}
        value={ProductConfiguration?.[name]}
        key={name + path}
      />
    ) : (
      <Integer
        dataSchema={ProductScheme}
        data={ProductConfiguration}
        name={name}
        dataPath={path}
        wrapper={wrapper || ''}
        key={name + path}
      />
    ),
    [ModelTypes.NUMBER]: isViewOnly ? (
      <InputViewOnly
        name={name}
        title={ProductScheme.name}
        value={ProductConfiguration?.[name]}
        key={name + path}
      />
    ) : (
      <Integer
        dataSchema={ProductScheme}
        data={ProductConfiguration}
        name={name}
        dataPath={path}
        wrapper={wrapper || ''}
        key={name + path}
      />
    ),
    [ModelTypes.STRING]: isViewOnly ? (
      <InputViewOnly
        name={name}
        title={ProductScheme.name}
        value={ProductConfiguration?.[name]}
        key={name + path}
      />
    ) : (
      <StringInput
        dataSchema={ProductScheme}
        data={ProductConfiguration}
        name={name}
        wrapper={wrapper || ''}
        dataPath={path}
        key={name + path}
      />
    ),
    [ModelTypes.VERSION]: isViewOnly ? (
      <InputViewOnly
        name={name}
        title={ProductScheme.name}
        value={ProductConfiguration?.[name]}
        key={name + path}
      />
    ) : (
      <StringInput
        dataSchema={ProductScheme}
        data={ProductConfiguration}
        name={name}
        wrapper={wrapper || ''}
        dataPath={path}
        key={name + path}
      />
    ),
    [ModelTypes.SECRET]: isViewOnly ? (
      <InputViewOnly
        type={ModelTypes.SECRET}
        name={name}
        title={ProductScheme.name}
        value={ProductConfiguration?.[name]}
        key={name + path}
      />
    ) : (
      <Secret
        dataSchema={ProductScheme}
        data={ProductConfiguration}
        name={name}
        wrapper={wrapper || ''}
        dataPath={path}
        key={name + path}
      />
    ),
    [ModelTypes.DATETIME]: isViewOnly ? (
      <InputViewOnly
        name={name}
        title={ProductScheme.name}
        value={ProductConfiguration?.[name]}
        key={name + path}
      />
    ) : (
      <DateTime
        dataSchema={ProductScheme}
        data={ProductConfiguration}
        name={name}
        dataPath={path}
        key={name + path}
      />
    ),
    [ModelTypes.TIME]: isViewOnly ? (
      <InputViewOnly
        name={name}
        title={ProductScheme.name}
        value={ProductConfiguration?.[name]}
        key={name + path}
      />
    ) : (
      <Duration
        dataSchema={ProductScheme}
        data={ProductConfiguration}
        name={name}
        wrapper={wrapper || ''}
        dataPath={path}
        key={name + path}
      />
    ),
    [ModelTypes.DOUBLE]: isViewOnly ? (
      <InputViewOnly
        name={name}
        title={ProductScheme.name}
        value={ProductConfiguration?.[name]}
        key={name + path}
      />
    ) : (
      <Double
        dataSchema={ProductScheme}
        data={ProductConfiguration}
        name={name}
        wrapper={wrapper || ''}
        dataPath={path}
        key={name + path}
      />
    ),
    [ModelTypes.DURATION]: isViewOnly ? (
      <InputViewOnly
        name={name}
        title={ProductScheme.name}
        value={ProductConfiguration?.[name]}
        key={name + path}
      />
    ) : (
      <Duration
        dataSchema={ProductScheme}
        data={ProductConfiguration}
        name={name}
        wrapper={wrapper || ''}
        dataPath={path}
        key={name + path}
      />
    ),
    default: <></>,
  }

  return elements[elementType]
}

export const componentConstructor = (
  ProductScheme: any = {},
  ProductConfiguration: any = {},
  name: string,
  wrapper?: string,
  classname?: string,
  path?: string[],
  elements?: any,
  isParentDisabled?: boolean,
  levelDeep?: number
): JSX.Element => {
  let elementsGUI = elements || {}

  if (ProductScheme.children) {
    const keys = Object.keys(ProductScheme.children)
    for (const item in keys) {
      const element = buildElementsExt(
        keys[item],
        ProductScheme.children[keys[item]],
        ProductConfiguration,
        wrapper,
        path,
        isParentDisabled,
        levelDeep
      )
      elementsGUI = {
        ...elementsGUI,
        [name]: { ...elementsGUI[name], [keys[item]]: [element] },
      }
    }
  }

  const keys = elementsGUI[name] ? Object.keys(elementsGUI[name]) : []
  return (
    <div className={`${classname}`}>
      {keys.map((el: string) => elementsGUI[name][el])}
    </div>
  )
}

export const routeConstructor = (
  ProductScheme: any = {},
  ProductConfiguration: any = {},
  name: string,
  guid: string
): JSX.Element => {
  const path: string[] = [guid, 'configuration', name]
  const productLocalPath: boolean = name.includes('\\') || name.includes('/')

  return (
    <div className="pb-4 pt-2">
      {productLocalPath && (
        <p className="w-100 flex-item pt-1 pb-1 border-bottom mb-1">
          <span style={{ fontSize: '18px' }} className="app-color-text h5">
            Path:
          </span>{' '}
          <span className="text-secondary">{name}</span>
        </p>
      )}
      {componentConstructor(
        ProductScheme[name],
        ProductConfiguration[name],
        name,
        '',
        'd-flex flex-wrap min-500 flex-container',
        path
      )}
    </div>
  )
}
