import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { IFooter } from '../types'

export const Footer: FC<IFooter> = ({ handleClose, handleOnSubmit }) => {
  return (
    <div className="ps-4 pe-4 mt-4 pb-3">
      <div className="d-flex justify-content-end w-100 pb-4 pt-2">
        <button
          className="app-btn-cancel btn-primory app-btn-main ms-auto me-2"
          type="button"
          onClick={handleClose}
        >
          <FormattedMessage id="buttons.cancel" />
        </button>
        <button
          type="button"
          className="app-btn-main app-btn-apply"
          onClick={handleOnSubmit}
        >
          <FormattedMessage id="buttons.download" />
        </button>
      </div>
    </div>
  )
}
