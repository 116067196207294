import React, { FC, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { fetchProduct } from '../../store/actions/configurationActions'
import { showModal } from '../../store/actions/modalActions'
import { ConfigurationsActionType } from '../../types/configurations'
import { ModalTypes } from '../../types/modals'
import { createEmptyDocument } from '../models/control'
import ProductContainer from '../ProductContainer/ProductContainer'
import { routeConstructor } from './control'
import { IGUIConstructor, ITabData } from './types'

const GUIConstructor: FC<IGUIConstructor> = ({
  path,
  guid,
  appName,
}): JSX.Element => {
  const dispatch = useDispatch()
  const { productsConfigurations } = useTypedSelector(
    (state) => state.configurations
  )
  const { fetchingError } = useTypedSelector((state) => state.configurations)
  const data = productsConfigurations[guid]
  const schema = data?.schema ? data?.schema : {}
  const configuration = data?.configuration ? data?.configuration : {}
  const configurationKeys = Object.keys(configuration)

  for (const item in configurationKeys) {
    if (!Object.keys(configuration[configurationKeys[item]] || {}).length) {
      const data = createEmptyDocument(
        schema[configurationKeys[item]],
        configurationKeys[item]
      )
      dispatch({
        type: ConfigurationsActionType.EMPTY_CONFIG_INIT,
        payload: { guid, name: [configurationKeys[item]], data },
      })
    }
  }

  useEffect(() => {
    dispatch(fetchProduct(guid))
  }, []) // eslint-disable-line

  useEffect(() => {
    if (fetchingError) {
      dispatch(
        showModal(ModalTypes.ERROR_MODAL, {
          request: () => fetchProduct(guid),
          title: <FormattedMessage id="errors.server.request" />,
          message: <FormattedMessage id="errors.server.requestMessage" />,
        })
      )
    }
  }, [fetchingError]) // eslint-disable-line

  const routeData: ITabData[] = Object.keys(schema).map((el: string) => {
    const keyName = el
    const fileName = el.split('\\')
    const name = fileName[fileName.length - 1].split('.')[0]

    return {
      name: name,
      path: `${path}/${name[0] + name[2]}`,
      component: routeConstructor(schema, configuration, el, guid),
      guid,
      keyName,
    }
  })

  return (
    <ProductContainer
      productPath={path}
      routesData={routeData}
      appName={appName}
      guid={guid}
    />
  )
}

export default React.memo(GUIConstructor)
