import { ProductStatus } from '../../../entity/Product'
import { IProduct, IProductGroup } from '../../../types/product'

export const getProductsList = (products: IProduct[], showActive: boolean) => {
  return showActive
    ? products.filter(
        (product: IProduct) => product.status === ProductStatus.ACTIVE
      )
    : products
}
