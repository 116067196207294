export const getIntegerSchema = (inputString: any, schema: any) => {
  if (typeof schema.greater_or_equal !== 'undefined') {
    inputString = inputString.test(
      'num',
      'Must be greater or equal',
      (val: any) => {
        if (!isNaN(val) && !Number.isInteger(Number(val))) {
          return false
        }
        if (!schema.is_required && (val === 'null' || val === null)) {
          return true
        }
        if (
          val &&
          val !== 'null' &&
          !isNaN(val) &&
          Number.isInteger(Number(val))
        ) {
          return BigInt(Number(val)) >= BigInt(Number(schema.greater_or_equal))
        }
        return Number(val) >= Number(schema.greater_or_equal)
      }
    )
  }
  if (typeof schema.greater_than !== 'undefined') {
    inputString = inputString.test(
      'num',
      'Must be greater than',
      (val: any) => {
        if (!isNaN(val) && !Number.isInteger(Number(val))) {
          return false
        }
        if (!schema.is_required && (val === 'null' || val === null)) {
          return true
        }
        if (
          val &&
          val !== 'null' &&
          !isNaN(val) &&
          Number.isInteger(Number(val))
        ) {
          return BigInt(Number(val)) > BigInt(Number(schema.greater_than))
        }
        return Number(val) > Number(schema.greater_than)
      }
    )
  }
  if (typeof schema.less_or_equal !== 'undefined') {
    inputString = inputString.test(
      'num',
      'Must be less or equal',
      (val: any) => {
        if (!isNaN(val) && !Number.isInteger(Number(val))) {
          return false
        }
        if (!schema.is_required && (val === 'null' || val === null)) {
          return true
        }
        if (
          val &&
          val !== 'null' &&
          !isNaN(val) &&
          Number.isInteger(Number(val))
        ) {
          return BigInt(Number(val)) <= BigInt(Number(schema.less_or_equal))
        }
        return Number(val) <= Number(schema.less_or_equal)
      }
    )
  }
  if (typeof schema.less_than !== 'undefined') {
    inputString = inputString.test('num', 'Must be less than', (val: any) => {
      if (!isNaN(val) && !Number.isInteger(Number(val))) {
        return false
      }
      if (!schema.is_required && (val === 'null' || val === null)) {
        return true
      }
      if (
        val &&
        val !== 'null' &&
        !isNaN(val) &&
        Number.isInteger(Number(val))
      ) {
        return BigInt(Number(val)) < BigInt(Number(schema.less_than))
      }
      return Number(val) < Number(schema.less_than)
    })
  }
  if (schema.is_required) {
    inputString = inputString.matches(/^-?[0-9]+$/gi).required()
  }
  if (!schema.is_required) {
    inputString = inputString
      .matches(/^-?[0-9/s/null]+$/gi)
      .transform((_: any, val: any) => String(val) || null)
      .notRequired()
      .nullable()
  }
  return inputString
}
