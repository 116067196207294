import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface INoData {
  className?: string
  messageId?: string
  opacity?: string
  style?: any
  type?: 'dropdown'
  isPopover?: boolean
}

export const NoData: FC<INoData> = ({
  className,
  messageId,
  opacity,
  style = {},
  type,
  isPopover,
}) => {
  if (type === 'dropdown') {
    return (
      <div
        style={{ ...style, opacity: 0.7 }}
        className={`text-white d-flex align-items-center ${
          isPopover ? 'justify-content-center' : 'ps-3'
        } ${className}`}
      >
        <FormattedMessage id={messageId || 'nodata'} />
      </div>
    )
  }

  return (
    <div
      style={{ ...style, opacity: opacity || '0.5' }}
      className={`h4 text-secondary vh-full d-flex align-items-center justify-content-center ${className}`}
    >
      <FormattedMessage id={messageId || 'nodata'} />
    </div>
  )
}
