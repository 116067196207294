import React, { FC, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import axios from 'axios'
import 'react-toastify/dist/ReactToastify.css'
import './App.scss'
import mainLogo from './img/logo.png'
import MESSAGES_EN from './i18n/en.json'
import { fetchSignIn } from './store/actions/authentication'
import { AuthActionType } from './types/user'
import SideBar from './components/SideBar/SideBar'
import GUIConstructor from './components/GUIConstructor/GUIConstructor'
import { useTypedSelector } from './hooks/useTypedSelector'
import useUnload from './hooks/useUnLoad'
import { IRoute, routes, RoutesPaths } from './router'
import LicenceInvalid from './components/LicenceInvalid'
import { IProduct } from './types/product'
import { LicenceActionType } from './store/reducer/licenceReducer'
import { hideModal } from './store/actions/modalActions'
import RightBarContainer from './components/rightbar/RightbarContainer'
import LogoutButton from './components/buttons/LogoutButton/LogoutButton'
import { AppLoginPage, AppNavbar, PrivateRoute } from 't4b-core-frontend'
import { ILogo } from 't4b-core-frontend/lib/entity/logo'
import { flattenMessages } from './utils/flattenMessages'
import { Modals } from './components/modals/Modals'
import { signOut } from './utils/authentication'

const appLogo: ILogo = {
  src: mainLogo,
  height: 48,
  width: 215,
  className: '',
  alt: 'logo',
  href: '/',
}

const APP_LANGUAGES: any = {
  en: 'English',
}

export const messages: any = {
  en: MESSAGES_EN,
}

export function getLanguage(): string {
  return localStorage.getItem('lang') || 'en'
}

export const UserContext = React.createContext<any>(null)

export const App: FC = () => {
  const [currentLang, onLangChange] = useState(getLanguage())
  const [sidebarHidden, setSidebarHidden] = useState(false)
  const dispatch = useDispatch()
  const { auth: isAuthenticated, licence } = useSelector((state: any) => state)
  const { products } = useTypedSelector((state) => state.products)

  useUnload((e: any) => {
    e.preventDefault()
    e.returnValue = ''
  })

  const handleLangChange = (lang: string): void => {
    localStorage.setItem('lang', lang)
    onLangChange(lang)
  }

  const handleSidebarClick = (): void => {
    setSidebarHidden(!sidebarHidden)
  }

  const onSignIn = (login: string, password: string): void => {
    fetchSignIn(login, password, () => {
      dispatch({ type: AuthActionType.SIGN_IN })
    })
  }

  const renderAppLoginPage = (): JSX.Element =>
    isAuthenticated ? (
      <Redirect to={RoutesPaths.SUMMARY} />
    ) : (
      <div className="ms-auto me-auto app-login-container">
        <AppLoginPage
          canBeRestored={false}
          canBeRegistered={false}
          signInFunc={onSignIn}
        />
      </div>
    )

  axios.interceptors.response.use(
    (config) => {
      return config
    },
    async (error) => {
      if (error.config?.url === RoutesPaths.REFRESH_TOKEN) {
        signOut()
        dispatch({ type: AuthActionType.SIGN_OUT })
        dispatch(hideModal())
      }
      if (error.response.status === 503) {
        signOut()
        dispatch({ type: AuthActionType.SIGN_OUT })
        dispatch({ type: LicenceActionType.LICENCE_INVALID })
      }
      throw error
    }
  )

  return (
    <IntlProvider
      locale={currentLang}
      messages={flattenMessages(messages[currentLang])}
    >
      <UserContext.Provider value={{ isAuthenticated }}>
        <div>
          <AppNavbar
            logo={appLogo}
            i18nLangs={APP_LANGUAGES}
            currentLangKey={currentLang}
            onLangChange={handleLangChange}
            isAuthenticated={isAuthenticated && licence}
            onSidebarHandlerClick={handleSidebarClick}
            sidebarHidden={sidebarHidden}
            exitButton={<LogoutButton />}
          />
        </div>
        {!licence ? (
          <LicenceInvalid />
        ) : (
          <div className="d-flex vh-full-header app">
            {!isAuthenticated && <Redirect to={{ pathname: '/login' }} />}
            {isAuthenticated && <SideBar sidebarHidden={sidebarHidden} />}

              <Switch>
                <Route path="/login" component={renderAppLoginPage} />
                <PrivateRoute
                  exact
                  path="/"
                  isAuthenticated={false}
                  component={renderAppLoginPage}
                />

                {routes.map((page: IRoute) => (
                  <PrivateRoute
                    exact={page.exact}
                    path={page.path}
                    isAuthenticated={isAuthenticated}
                    component={page.component}
                    key={page.path}
                  />
                ))}

                {products.map((product: IProduct) => {
                  return (
                    <PrivateRoute
                      path={`/${product.guid}`}
                      isAuthenticated={isAuthenticated}
                      key={product.guid}
                      component={
                        <GUIConstructor
                          path={`/${product.guid}`}
                          guid={product.guid}
                          appName={product.name}
                        />
                      }
                    />
                  )
                })}
              </Switch>
              <RightBarContainer />
     
          </div>
        )}
        <ToastContainer
          pauseOnHover
          closeOnClick={false}
          position="bottom-right"
        />
        <Modals />
      </UserContext.Provider>
    </IntlProvider>
  )
}
