import { IProduct, IProductGroup } from '../types/product'

export const getOtherProducts = (
  productsGroups: IProductGroup[],
  products: IProduct[]
) => {
  const groupedProducts = productsGroups.reduce(
    (acc: string[], res: IProductGroup) => {
      return [...acc, ...res?.entries]
    },
    []
  )

  const othersProductsList = products.filter(
    (product: IProduct) => !groupedProducts.includes(product.guid)
  )

  return othersProductsList
}
