import React, { FC, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useFormValidation } from '../../hooks/useFormValidation'
import { modifyCurrentUser } from '../../store/actions/usersActions'
import { controllerErrors } from '../../utils/validationErrors/controllerErrors'
import { buildControlsExt, passwordInput } from '../inputs/controls'
import { getUserModel, getValidationSchema } from '../../utils/controller'
import { User, UserTypes } from '../../entity/User'
import { getUserType } from '../../utils/userRights'
import { getLabel } from '../../validators'
import { FormattedMessage } from 'react-intl'
import { getJwt } from '../../utils/authentication'

const Account: FC = () => {
  const dispatch = useDispatch()
  const user = new User()
  const [openUsers, setOpenUsers] = useState<boolean>(false)
  const userLocal = getUserModel(user, true)
  const schema = getValidationSchema(true)
  const userType = getUserType(user.permissions)
  const [inputState, setInputState, touched, setTouched, validationResult] =
    useFormValidation(
      userLocal,
      schema,
      'newPassword',
      'confirmPassword',
      'oldPassword'
    )
  const IsAbleToConfirm: boolean = !!Object.keys(validationResult).length

  const handleReset = (): void => {
    setInputState(userLocal)
    setTouched({})
    setOpenUsers(false)
  }

  const passwordInputs = buildControlsExt(
    [
      passwordInput({
        name: 'oldPassword',
        label: 'user.account.currentPassword',
        isColumn: true,
        className: 'mb-2',
        isRequired: true,
        inputType: 'password',
        errMsg: controllerErrors.adminControllerOldPassword(inputState),
      }),
      passwordInput({
        name: 'newPassword',
        label: 'user.account.newPassword',
        isColumn: true,
        className: 'mb-2',
        isRequired: true,
        inputType: 'password',
        errMsg: controllerErrors.adminControllerNewPassword(inputState),
      }),
      passwordInput({
        name: 'confirmPassword',
        label: 'user.account.confirmPassword',
        isColumn: true,
        className: 'mb-2',
        isRequired: true,
        inputType: 'password',
        errMsg: controllerErrors.adminControllerRepeatPassword(inputState),
      }),
    ],
    inputState,
    setInputState,
    '',
    touched,
    setTouched,
    validationResult
  )

  const handleOnApply = (): void => {
    const user = {
      access_token: getJwt(),
      oldPassword: inputState.oldPassword,
      newPassword: inputState.newPassword,
    }
    handleReset()
    dispatch(modifyCurrentUser(user, '/current/change_password'))
  }

  const getTouched = (): boolean => {
    if (openUsers) return false
    return JSON.stringify(inputState) === JSON.stringify(userLocal)
  }

  const isTouched = getTouched()

  return (
    <div className="d-flex">
      <Card style={{ minWidth: '450px' }}>
        <Card.Header>
          <h3 className="app-color-text">{user.login}</h3>
        </Card.Header>
        <Card.Body>
          <Card.Title>
            <div className="d-flex flex-column border-bottom">
              <p className="navi-blue mb-3 h5 d-flex align-items-center">
                <span className="d-block app-w-30">
                  <FormattedMessage id="user.account.role" />
                </span>
                <span className="label-main d-block text-capitalize">
                  {userType}
                </span>
              </p>
              {userType === UserTypes.NOT_STANDARD_ROLE && (
                <p className="navi-blue mb-3 d-flex align-items-center">
                  <span className="d-block h5 app-w-30">
                    <FormattedMessage id="user.account.permissions" />
                  </span>
                  <span className="label-main d-flex flex-column text-capitalize">
                    {user.permissions.map(
                      (permission: string, index: number) => (
                        <span key={permission}>
                          {getLabel(permission)}
                          {index + 1 !== user.permissions.length && ','}
                        </span>
                      )
                    )}
                  </span>
                </p>
              )}
            </div>
          </Card.Title>
          <div>
            <p className="navi-blue mb-3 mt-3 h5">
              <FormattedMessage id="user.account.changePassword" />
            </p>
            <div id="example-collapse-text">{passwordInputs}</div>
          </div>
        </Card.Body>
        <Card.Footer className="mt-4">
          <Button
            onClick={handleOnApply}
            disabled={isTouched || IsAbleToConfirm}
            variant="primary"
          >
            <FormattedMessage id="buttons.apply" />
          </Button>
          <Button
            disabled={isTouched}
            onClick={handleReset}
            className="ms-2"
            variant="secondary"
          >
            <FormattedMessage id="buttons.cancel" />
          </Button>
        </Card.Footer>
      </Card>
    </div>
  )
}

export default Account
