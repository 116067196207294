import React, { FC } from 'react'
import { Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

interface IInnerModal {
  show: boolean
  setShow: any
  titleMessage: string
  bodyMessage: string
  btnCancelMsg: string
  btnApplyMsg: string
  onAction: () => void
}

export const InnerModal: FC<IInnerModal> = ({
  show,
  setShow,
  titleMessage,
  bodyMessage,
  btnCancelMsg,
  btnApplyMsg,
  onAction,
}) => {
  const handleClose = () => setShow(false)

  return (
    <div className={show ? 'bg-blur' : ''}>
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id={titleMessage} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage id={bodyMessage} />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="app-btn-cancel btn-primory app-btn-main ms-auto me-2"
            type="button"
            onClick={handleClose}
          >
            <FormattedMessage id={btnCancelMsg} />
          </button>
          <button
            type="button"
            className="app-btn-delete btn-primory app-btn-main me-2"
            onClick={onAction}
          >
            <FormattedMessage id={btnApplyMsg} />
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
