import React, { FC, useEffect } from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { changeGroupPositions } from '../../store/actions/products/groupsActions'
import { IProductGroup, ProductActionType } from '../../types/product'
import { NoData } from '../NoData'
import { ProductsGroups } from './Components/ProductsGroups'
import { ProductsOthers } from './Components/ProductsOthers'
import { getProductsList } from './utils'

interface IProducts {
  hideInactiveState: { [key: string]: boolean }
}

export const Products: FC<IProducts> = ({ hideInactiveState }) => {
  const dispatch = useDispatch()
  const { products, productsGroups } = useTypedSelector(
    (state) => state.products
  )
  const productsList = getProductsList(products, hideInactiveState.showActive)

  useEffect(() => {
    dispatch({ type: ProductActionType.SET_PRODUCT_ROUTED, payload: true })
  }, []) // eslint-disable-line

  const handleOnDragEnd = (result: any): void => {
    if (!result.destination) return

    const items = productsGroups.map((group: any) => group.group_id)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    dispatch(changeGroupPositions(items))
  }

  return (
    <>
      {!products.length && !productsList.length && !productsGroups.length ? (
        <NoData />
      ) : (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable direction="vertical" droppableId="app-products-groups">
            {(provided: DroppableProvided) => (
              <div ref={provided.innerRef}>
                {productsGroups.map(
                  (groupData: IProductGroup, index: number) => (
                    <Draggable
                      key={String(groupData.group_id)}
                      draggableId={String(groupData.group_id)}
                      index={index}
                    >
                      {(provided: DraggableProvided) => (
                        <div
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                        >
                          <ProductsGroups
                            productsList={productsList}
                            groupData={groupData}
                            hideInactive={hideInactiveState.showActive}
                            key={groupData.group_id}
                            dragHandleProps={{ ...provided.dragHandleProps }}
                            ref={provided.innerRef}
                          />
                        </div>
                      )}
                    </Draggable>
                  )
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      <ProductsOthers
        productsList={productsList}
        hideInactive={hideInactiveState.showActive}
      />
    </>
  )
}
