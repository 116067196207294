import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import PromptBtn from '../buttons/PromptBtn/PromptBtn'
import { ICheckBoxInput } from './types'

export const RadioButton: FC<ICheckBoxInput> = ({
  state,
  setState,
  name,
  label,
  isDisabled,
  className,
  description,
}) => {
  const isAccordionItemSelected = (value: string): boolean => state === value

  const handleOnClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setState(event.target.value)
  }

  return (
    <div className={`${className} m-1 mt-2 mb-2 d-flex align-items-center`}>
      <label className="pointer d-block">
        <span
          className={`d-flex align-items-center ${description && 'mt-2 me-1'}`}
        >
          <input
            type="radio"
            className="checkbox-lg pointer"
            onChange={handleOnClick}
            checked={isAccordionItemSelected(name)}
            disabled={!!isDisabled}
            name={name}
            value={name}
          />
          <span
            className={`label-main ms-2 text-capital ${
              state?.[name] ? '' : 'text-secondary'
            } `}
          >
            <FormattedMessage id={label} />
          </span>
        </span>
      </label>
      {description && <PromptBtn className="mt-2" prompt={description ?? ''} />}
    </div>
  )
}
