import React, { FC } from 'react'
import { Card } from 'react-bootstrap'
import { IProduct } from '../../types/product'
import ProductCardHeader from './components/ProductCardHeader'
import ProductCardBody from './components/ProductCardBody'
import { AnimatedContainer } from '../AnimatedContainer'
import { useTypedSelector } from '../../hooks/useTypedSelector'

interface IProductCard {
  product: IProduct
  className?: string
  groupId?: string
}

const ProductCard: FC<IProductCard> = ({ product, className, groupId }) => {
  const { productLoading } = useTypedSelector((state) => state.products)
  const isLoading = productLoading === product.guid

  return (
    <AnimatedContainer loading={isLoading}>
      <Card
        className={`m-2 h-auto ${className}`}
        style={{ width: '23rem' }}
        key={product.name}
      >
        <ProductCardHeader groupId={groupId} product={product} />
        <ProductCardBody product={product} />
      </Card>
    </AnimatedContainer>
  )
}

export default ProductCard
