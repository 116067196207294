import React, { FC } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { hideModal } from '../../store/actions/modalActions'

const MainModal: FC = () => {
  const { data } = useTypedSelector((state) => state.modal)
  const dispatch = useAppDispatch()

  const handleClose = (): void => {
    dispatch(hideModal())
  }

  const handleOnSubmit = (): void => {
    data.onSubmit()
    handleClose()
  }

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {data.title ? data.title : <FormattedMessage id="please-confirm" />}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="blockquote m-0 word-break">{data.body}</div>
      </Modal.Body>
      <Form>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" type="submit" onClick={handleClose}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button variant="primary" type="submit" onClick={handleOnSubmit}>
            <FormattedMessage id="confirm" />
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default MainModal
