import React, { FC, useState } from 'react'
import { Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { hideModal } from '../../../../store/actions/modalActions'
import { deleteProductsGroup } from '../../../../store/actions/products/groupsActions'
import { deleteProducts } from '../../../../store/actions/products/productsActions'
import { InnerModal } from '../../InnerModal'

interface IFooter {
  handleApply: () => void
  validationResult: { [key: string]: boolean }
  groupId: string
  groupEntries: any[]
}

export const Footer: FC<IFooter> = ({
  validationResult,
  handleApply,
  groupId,
  groupEntries,
}) => {
  const dispatch = useAppDispatch()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showDisbandModal, setShowDisbandModal] = useState(false)
  const isValid = !!Object.keys(validationResult).length

  const deleteGroupHandle = (): void => {
    setShowDeleteModal(true)
  }

  const disbandGroupHandle = (): void => {
    setShowDisbandModal(true)
  }

  const disbandGroupAction = (): void => {
    dispatch(deleteProductsGroup(groupId, dispatch(hideModal())))
    setShowDisbandModal(false)
  }

  const deleteGroupAction = (): void => {
    setShowDeleteModal(false)
    dispatch(hideModal())
    dispatch(deleteProductsGroup(groupId))
    dispatch(deleteProducts(groupEntries.map((el) => el.value)))
  }

  return (
    <>
      <Form className="ps-4 pe-4">
        <div className="d-flex justify-content-end pb-5 pt-2">
          <button
            className="app-btn-cancel btn-primory app-btn-main ms-auto me-2"
            type="button"
            onClick={disbandGroupHandle}
          >
            <FormattedMessage id="modals.editGroup.disband" />
          </button>
          <button
            type="button"
            className="app-btn-delete btn-primory app-btn-main me-2"
            onClick={deleteGroupHandle}
          >
            <FormattedMessage id="modals.editGroup.delete" />
          </button>
          <button
            type="button"
            className="app-btn-main app-btn-apply"
            onClick={handleApply}
            disabled={isValid}
          >
            <FormattedMessage id="buttons.apply" />
          </button>
        </div>
      </Form>
      <InnerModal
        setShow={setShowDeleteModal}
        show={showDeleteModal}
        titleMessage="modals.editGroup.deleteTheGroup"
        bodyMessage="modals.editGroup.deleteGroupConfirmation"
        btnCancelMsg="buttons.cancel"
        btnApplyMsg="modals.editGroup.deleteTheGroup"
        onAction={deleteGroupAction}
      />
      <InnerModal
        setShow={setShowDisbandModal}
        show={showDisbandModal}
        titleMessage="modals.editGroup.disband"
        bodyMessage="modals.editGroup.disbandGroupConfirmation"
        btnCancelMsg="buttons.cancel"
        btnApplyMsg="modals.editGroup.disband"
        onAction={disbandGroupAction}
      />
    </>
  )
}
